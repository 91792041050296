body {
    font-family: "Roboto", sans-serif;
}
p {
    margin: auto;
}
section {
    padding: 100px 0px;
}
.search-section1 form {
    margin: auto;
}
.box-shadow ::placeholder {
    color: #c4c4c4 !important;
    font-style: italic;
    opacity: 1; /* Firefox */
}
form label {
    color: #fff;
}
.search-section1 .div1 button:hover {
    background: #35bddb;

    color: #fff;
    box-shadow: 0px 4px 18px 4px #156083;
    transition: 0.5s;
}
.search-section1 .div1 form {
    background-color: #1b7daa;
    padding: 25px 90px;
    margin-bottom: 21px;
    border-radius: 13px;
    position: relative;
}
.search-section1 .box-shadow input {
    height: 45px;
    border-radius: 6px;
    border: 0px;
    padding: 0px 25px;
    -webkit-box-shadow: 0px 2px 5px 0px rgb(70, 70, 70);
    -moz-box-shadow: 0px 2px 5px 0px rgba(70, 70, 70);
    box-shadow: 0px 2px 5px 0px rgba(70, 70, 70);
}
.search-section1 .btn1 {
    width: 100%;
    border: 0px;
    background: #021f0b;
    border-radius: 6px;
    color: #fff;
    padding: 0px;
    margin: 0px;
    height: 45px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(70, 70, 70);
    -moz-box-shadow: 0px 2px 5px 0px rgba(70, 70, 70);
    box-shadow: 0px 2px 5px 0px rgba(70, 70, 70);
    position: relative;
    top: 32px;
}

.terms-section2 {
    background-color: #f6f9fc;
}
.terms-section2 ul li {
    list-style-type: decimal;
    padding-bottom: 5px;
}
.terms-section2 ul {
    margin-left: -16px;
}

.blue-bg {
    color: #fff;
    background: #35bdda;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0px;
}
.bg-gray {
    background-color: #f8f8f8;
    padding: 18px 25px;
    border-radius: 6px;
}
.payment-table tr {
    height: 50px;
}
.payment-table .total {
    font-weight: bold;
    padding-right: 60px;
    font-size: 20px;
}
.pay-btn {
    background-color: #00a651;
    border: 0px;
    color: #fff;
    padding: 10px 30px;
    width: 165px;
    border-radius: 6px;
}
.pay-btn:hover {
    background: #021f0b;

    color: #fff;
}
.pay-success a,
.pay-fail a {
    color: #35bdda;
    position: relative;
    top: 50px;
}
.pay-success h4 {
    color: #00a651;
    padding-bottom: 50px;
}
.pay-success-table tr {
    height: 30px;
    color: #444;
}
.pay-fail h4 {
    color: red;
    padding-bottom: 35px;
}

footer {
    background-color: #1b7daa;
    color: #fff;
}

.footer-sub {
    background-color: #000;
    color: #fff;
}
footer {
    padding: 100px 0px;
}

.footer-sub {
    padding: 30px;
}
footer .btn1 {
    background: #021f0b;
    border-radius: 6px;
    color: #61ce70;
    border: 0px;
    padding: 10px 20px;
    margin-top: 20px;
}
footer .btn1:hover {
    background-color: #215387;
    color: #35bddb;
}
footer ul.social-icons li {
    list-style-type: none;
    float: left;
}
footer ul.social-icons a {
    color: #000;
    background: #fff;
    margin-right: 10px;
    padding: 6px;
    border-radius: 5px;
}
footer ul.social-icons a:hover {
    color: #fff;
    background: #000;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .search-section1 .btn1 {
        top: 10px;
    }
    .search-section1 .div1 form {
        padding: 55px 30px;
    }
    .pay-success-table {
        width: 100% !important;
    }
    .payment-table .total {
        padding-right: 35px;
        font-size: 18px;
    }
    footer ul.social-icons {
        margin-left: -32px;
    }
    footer p {
        margin-bottom: 60px;
    }
}
